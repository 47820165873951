export default function MobileMenuItemContainer({
  children,
  ...props
}: React.ComponentPropsWithoutRef<'li'>) {
  return (
    <li
      className="box-content p-5 border-t border-grey-90 first:border-t-0"
      role="menuitem"
      {...props}
    >
      {children}
    </li>
  );
}
