import { useTranslations } from 'next-intl';

import { HorizontalDivider } from '@/components/HorizontalDivider';
import { useAuthData } from '@/hooks/useAuthData';
import { Claims, Scopes } from '@/lib/users/claims';
import { MEMBER_PORTAL_LOGIN_URL } from '@/utils/constants';

import UserModal from '../../UserModal';
import UserIcon from '../../icons/UserIcon';
import useUserModal from '../hooks/useUserModal';
import HorizontalNavDropdown, {
  HorizontalNavDropdownProps,
} from '../shared/HorizontalNavDropdown';
import SubMenuItem from '../shared/SubMenuItem';

type DefaultDesktopUserDropdownProps = Pick<
  HorizontalNavDropdownProps,
  'onKeyDown' | 'onMouseEnter' | 'isExpanded' | 'className'
> & { onSubMenuClick: () => void };

const widthSubMenu = 205;

export default function DefaultDesktopUserDropdown({
  onSubMenuClick,
  ...dropdownProps
}: DefaultDesktopUserDropdownProps) {
  const { isAuthenticated, isLoading, checkClaims } = useAuthData();
  const t = useTranslations('nav');
  const {
    isClosable,
    modalType,
    email,
    closeModal,
    logout,
    handleLoginSuccess,
    showLoginModal,
    showSignupModal,
    showResetModal,
  } = useUserModal();
  const showMemberPortalMenu =
    checkClaims(Scopes.ClientAccess, [Claims.CanAccessMemberPortal]) || false;

  const handleLogout = async () => {
    onSubMenuClick();
    logout();
  };

  const handleLogin = () => {
    onSubMenuClick();
    showLoginModal();
  };

  const handleSignup = () => {
    onSubMenuClick();
    showSignupModal();
  };

  return (
    <>
      <HorizontalNavDropdown
        icon={<UserIcon className="header-transparent:text-white" />}
        arialLabel={t('account')}
        widthSubMenu={widthSubMenu}
        subMenuClassName="max-xl:right-0"
        dataTestId={isAuthenticated ? 'nav-account' : 'nav-members'}
        {...dropdownProps}
      >
        {isLoading && <div>{t('loading')}...</div>}
        {!isLoading && isAuthenticated && (
          <>
            {showMemberPortalMenu && (
              <>
                <SubMenuItem
                  href={MEMBER_PORTAL_LOGIN_URL}
                  isExternalLink={true}
                  dataTestId="nav-member-portal-link"
                >
                  {t('member-portal')}
                </SubMenuItem>
                <HorizontalDivider />
                <p className="text-xs font-bold uppercase">{t('on-demand')}</p>
              </>
            )}
            <SubMenuItem
              href={'/account/reservations'}
              dataTestId="nav-reservations"
            >
              {t('reservations')}
            </SubMenuItem>
            <SubMenuItem
              href={'/account/payment-methods'}
              dataTestId="nav-payment-methods"
            >
              {t('payment-methods')}
            </SubMenuItem>
            <SubMenuItem
              href={'/account/account-settings'}
              dataTestId="nav-account"
            >
              {t('account')}
            </SubMenuItem>
            <HorizontalDivider />
            <SubMenuItem onClick={handleLogout} dataTestId="nav-logout">
              {t('logout')}
            </SubMenuItem>
          </>
        )}
        {!isLoading && !isAuthenticated && (
          <>
            <SubMenuItem onClick={handleLogin} dataTestId="nav-login">
              {t('login')}
            </SubMenuItem>
            <SubMenuItem onClick={handleSignup} dataTestId="nav-signup">
              {t('signup')}
            </SubMenuItem>
          </>
        )}
      </HorizontalNavDropdown>

      {modalType && (
        <UserModal
          modal={modalType}
          email={email}
          closable={isClosable}
          onLoginSuccess={handleLoginSuccess}
          onClose={closeModal}
          onSignUpClick={showSignupModal}
          onLoginClick={showLoginModal}
          onResetClick={showResetModal}
        />
      )}
    </>
  );
}
