import { useState } from 'react';

function useToggle(defaultValue = false): [boolean, (value?: boolean) => void] {
  const [value, setValue] = useState(defaultValue);

  const toggleValue = (value?: boolean) => {
    setValue((prevValue) => (value === undefined ? !prevValue : value));
  };

  return [value, toggleValue];
}

export default useToggle;
