'use client';

import { useTranslations } from 'next-intl';

import { useAuthData } from '@/hooks/useAuthData';

import UserModal from '../../UserModal';
import useUserModal from '../hooks/useUserModal';
import MobileMenuItem from '../shared/MobileMenuItem';
import MobileMenuItemContainer from '../shared/MobileMenuItemContainer';
import MobileSubMenuItem from '../shared/MobileSubMenuItem';

export default function DefaultUserMobileMenuItem() {
  const { isAuthenticated, isLoading } = useAuthData();
  const t = useTranslations('nav');
  const {
    isClosable,
    modalType,
    email,
    closeModal,
    logout,
    handleLoginSuccess,
    showLoginModal,
    showSignupModal,
    showResetModal,
  } = useUserModal({ isMobile: true });

  return (
    <>
      <MobileMenuItemContainer>
        <MobileMenuItem dataTestId="nav-members">{t('members')}</MobileMenuItem>
        {isAuthenticated && !isLoading && (
          <ul role="menu">
            <MobileSubMenuItem
              href={'/account/reservations'}
              dataTestId="nav-reservations"
            >
              {t('reservations')}
            </MobileSubMenuItem>
            <MobileSubMenuItem
              href={'/account/payment-methods'}
              dataTestId="nav-payment-methods"
            >
              {t('payment-methods')}
            </MobileSubMenuItem>
            <MobileSubMenuItem
              href={'/account/account-settings'}
              dataTestId="nav-account"
            >
              {t('account')}
            </MobileSubMenuItem>
            <MobileSubMenuItem
              onClick={() => logout()}
              dataTestId="nav-logout"
              className="font-bold"
            >
              {t('logout')}
            </MobileSubMenuItem>
          </ul>
        )}
        {!isAuthenticated && !isLoading && (
          <ul role="menu">
            <MobileSubMenuItem onClick={showLoginModal} dataTestId="nav-login">
              {t('login')}
            </MobileSubMenuItem>
            <MobileSubMenuItem
              onClick={showSignupModal}
              dataTestId="nav-signup"
            >
              {t('signup')}
            </MobileSubMenuItem>
          </ul>
        )}
      </MobileMenuItemContainer>

      {modalType && (
        <UserModal
          modal={modalType}
          email={email}
          closable={isClosable}
          onLoginSuccess={handleLoginSuccess}
          onClose={closeModal}
          onSignUpClick={showSignupModal}
          onLoginClick={showLoginModal}
          onResetClick={showResetModal}
        />
      )}
    </>
  );
}
