import ChevronIcon from '../../icons/ChevronIcon';
import ButtonMenuItem from './ButtonMenuItem';
import MenuItem from './MenuItem';

import clsx from 'clsx';

export type HorizontalNavDropdownProps = {
  dataTestId: string;
  className?: string;
  isExpanded: boolean;
  isHighlighted?: boolean;
  icon?: React.ReactNode;
  label?: string;
  arialLabel?: string;
  widthSubMenu?: number;
  children: React.ReactNode;
  btnClassName?: string;
  subMenuClassName?: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLElement>) => void;
  onMouseEnter: (e: React.MouseEvent<HTMLElement>) => void;
};

const DEFAULT_WIDTH_SUB_MENU = 196;

export default function HorizontalNavDropdown({
  dataTestId,
  className,
  isExpanded,
  isHighlighted,
  children,
  icon,
  label,
  arialLabel,
  widthSubMenu,
  btnClassName,
  subMenuClassName,
  onKeyDown,
  onMouseEnter,
}: HorizontalNavDropdownProps) {
  return (
    <MenuItem
      role="menuitem"
      className={clsx('group/menu', className)}
      isHighlighted={isHighlighted}
      aria-haspopup={true}
      aria-expanded={isExpanded}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
    >
      <ButtonMenuItem
        dataTestId={dataTestId}
        className={clsx('flex items-center gap-1', btnClassName)}
        onClick={onMouseEnter}
        aria-label={arialLabel}
      >
        {icon}
        {label}{' '}
        <ChevronIcon
          className={clsx(
            'header-transparent:stroke-white header-transparent:opacity-[0.8] header-no-transparent:stroke-teal-5',
            'focus:rotate-180 group-hover/menu:rotate-180 transition-transform duration-200'
          )}
          isBold={true}
        />
      </ButtonMenuItem>

      <div
        className={clsx(
          'absolute bg-white p-5 rounded-b-sm top-[69px] box-border border-t border-grey-90',
          isExpanded ? 'block' : 'hidden',
          subMenuClassName
        )}
        style={{
          width: `${widthSubMenu || DEFAULT_WIDTH_SUB_MENU}px`,
          boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.15)',
        }}
      >
        <ul
          role="menu"
          aria-orientation="vertical"
          className="flex flex-col gap-5"
        >
          {children}
        </ul>
      </div>
    </MenuItem>
  );
}
