import { Link } from '@/components/Link';

import clsx from 'clsx';

export default function LinkMenuItem({
  dataTestId,
  isExternalLink,
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'a'> & {
  href: string;
  dataTestId?: string;
  isExternalLink?: boolean;
}) {
  return (
    <Link
      data-testid={dataTestId}
      rel={isExternalLink ? 'noopener' : ''}
      target={isExternalLink ? '_blank' : '_self'}
      className={clsx('h-full flex items-center', className)}
      {...rest}
    />
  );
}
