'use client';

import { ReactNode } from 'react';

import { useSupplier } from '@/hooks/useSupplier';

export default function FooterFilter({ children }: { children: ReactNode }) {
  const { isNuveenSupplier } = useSupplier();

  if (isNuveenSupplier) {
    return null;
  }

  return <>{children}</>;
}
