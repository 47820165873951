'use client';

import { useEffect, useState } from 'react';

export function useTransparentHeader(enabled = false) {
  const [isOnTop, setIsOnTop] = useState(true);
  const [isHover, setIsHover] = useState(false);

  const isTransparent = enabled && !isHover && isOnTop;

  useEffect(() => {
    if (!enabled) return;

    const handleScroll = () => {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      setIsOnTop(() => scrollTop === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return { isTransparent, setIsHover };
}
