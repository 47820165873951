import { ReactNode } from 'react';

import { Link } from '@/components/Link';

import clsx from 'clsx';

type MobileSubMenuItemProps = {
  children: ReactNode;
  dataTestId: string;
  href?: string;
  isExternal?: boolean;
  className?: string;
  onClick?: () => void;
};

export default function MobileSubMenuItem({
  children,
  href,
  isExternal,
  dataTestId,
  className,
  onClick,
}: MobileSubMenuItemProps) {
  return (
    <li className={clsx('my-7 last:my-2', className)} role="menuitem">
      {href ? (
        <Link
          href={href}
          data-testid={dataTestId}
          rel={isExternal ? 'noopener' : ''}
          target={isExternal ? '_blank' : '_self'}
        >
          {children}
        </Link>
      ) : (
        <button onClick={onClick} data-testid={dataTestId}>
          {children}
        </button>
      )}
    </li>
  );
}
