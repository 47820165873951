import { usePathname } from 'next/navigation';

import clsx from 'clsx';

type MenuItemProps = {
  url?: string;
  dataTestId?: string;
  isHighlighted?: boolean;
} & React.ComponentPropsWithoutRef<'li'>;

export default function MenuItem({
  url,
  dataTestId,
  isHighlighted,
  className,
  ...rest
}: MenuItemProps) {
  const pathName = usePathname();
  const isCurrentRoute = pathName === url;

  return (
    <li
      role="menuitem"
      className={clsx(
        'header-transparent:text-white header-no-transparent:text-teal-5 self-center',
        'flex items-center gap-1 text-[15px] h-full',
        'before:absolute before:bottom-0 before:left-0 before:right-0',
        'before:h-1 before:bg-teal-5 before:opacity-0 before:transition-all before:duration-100 hover:before:opacity-100',
        (isCurrentRoute || isHighlighted) &&
          'header-no-transparent:before:opacity-100',
        className
      )}
      data-testid={dataTestId}
      {...rest}
    />
  );
}
