import { useTranslations } from 'next-intl';
import { ComponentPropsWithoutRef } from 'react';

import { LinkButtonThemes } from '@/components/buttons/LinkButtonThemes';
import { SecondaryLinkButton } from '@/components/buttons/SecondaryLinkButton';
import { useAuthData } from '@/hooks/useAuthData';
import { Claims, Scopes } from '@/lib/users/claims';

type JoinNowProps = Omit<ComponentPropsWithoutRef<'a'>, 'href' | 'className'>;

export default function JoinNow(props: JoinNowProps) {
  const t = useTranslations('nav');
  const { isAuthenticated, checkClaims } = useAuthData();

  const showMemberPortalMenu =
    checkClaims(Scopes.ClientAccess, [Claims.CanAccessMemberPortal]) || false;

  const showPrimaryCta =
    (isAuthenticated && !showMemberPortalMenu) || !isAuthenticated;

  if (showPrimaryCta) {
    return (
      <SecondaryLinkButton
        href="/location-form"
        className="self-center leading-3 shrink-0 h-fit"
        theme={LinkButtonThemes.Home}
        data-testid="nav-join-now"
        {...props}
      >
        {isAuthenticated ? t('book-tour') : t('join')}
      </SecondaryLinkButton>
    );
  }
  return null;
}
