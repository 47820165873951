export enum Scopes {
  ClientAccess = 'client_access',
  AccessControl = 'access_control',
  Inventory = 'inventory',
  OrderPaymentBilling = 'opb',
}

export enum Claims {
  // Client Access
  CanAccessMemberPortal = 'can_access_member_portal',
}
