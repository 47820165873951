import { useRef, useState } from 'react';

import { Keys } from '@/models/Keyboard.enum';

export default function useDropDowns() {
  const menuRef = useRef<HTMLUListElement>(null);
  const [openedDropdownId, setOpenedDropdownId] = useState<string>();

  const openDropdown = (dropdownId: string) => setOpenedDropdownId(dropdownId);
  const closeDropdown = () => {
    setOpenedDropdownId(undefined);
  };

  const toggleDropdown = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    dropdownId: string
  ) => {
    e.preventDefault();

    if (dropdownId === openedDropdownId) {
      closeDropdown();
    } else {
      openDropdown(dropdownId);
    }
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    dropdownId: string
  ) => {
    if (e.key === Keys.Enter) {
      toggleDropdown(e, dropdownId);
    }
  };

  return {
    menuRef,
    openedDropdownId,
    toggleDropdown,
    onKeyDown,
    openDropdown,
    closeDropdown,
  };
}
