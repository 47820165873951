import clsx from 'clsx';

type Props = {
  className?: string;
};

export function HorizontalDivider(props: Props) {
  const { className } = props;
  return <hr className={clsx('border-grey-60', className)} />;
}
