import { IconProps } from './types';

export default function UserIcon(props: IconProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="heroicons-outline/user">
        <g id="Vector">
          <path
            d="M11.8125 5C11.8125 6.5533 10.5533 7.8125 9 7.8125C7.4467 7.8125 6.1875 6.5533 6.1875 5C6.1875 3.4467 7.4467 2.1875 9 2.1875C10.5533 2.1875 11.8125 3.4467 11.8125 5Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.37585 15.5887C3.42858 12.5277 5.92641 10.0625 9 10.0625C12.0737 10.0625 14.5715 12.5278 14.6242 15.5889C12.9121 16.3745 11.0073 16.8125 9.00024 16.8125C6.993 16.8125 5.08807 16.3744 3.37585 15.5887Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}
