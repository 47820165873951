import clsx from 'clsx';

type BaseHorizontalNavProps = {
  children: React.ReactNode;
  menuRef: React.RefObject<HTMLUListElement>;
  className?: string;
  onMouseLeave: () => void;
};

export default function BaseHorizontalNav({
  children,
  menuRef,
  className,
  onMouseLeave,
}: BaseHorizontalNavProps) {
  return (
    <nav
      className="flex items-center justify-end flex-1 h-full ml-20 lg:ml-30 xl:ml-40"
      aria-label="main-menu-label"
    >
      <ul
        className={clsx(
          'flex items-stretch h-full space-x-2 text-base leading-4 xl:space-x-12 text-teal-5 w-full header-transparent:text-white',
          className
        )}
        role="menubar"
        aria-orientation="horizontal"
        ref={menuRef}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </ul>
    </nav>
  );
}
