import clsx from 'clsx';

export default function ButtonMenuItem({
  dataTestId,
  className,
  ...rest
}: React.ComponentPropsWithoutRef<'button'> & { dataTestId: string }) {
  return (
    <button
      data-testid={dataTestId}
      className={clsx('h-full', className)}
      {...rest}
    />
  );
}
