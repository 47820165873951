'use client';

import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React, { useEffect } from 'react';

import useToggle from '@/hooks/useToggle';
import closeIcon from '@/public/icons/closeIcon.svg';

import HamburgerIcon from './HamburgerIcon';

import clsx from 'clsx';

type MobileButtonProps = {
  className?: string;
  children: React.ReactNode;
};

export default function MobileButton({
  className,
  children,
}: MobileButtonProps) {
  const [isMobileMenuOpened, toggleMenuDisplay] = useToggle(false);
  const t = useTranslations('nav');

  useEffect(() => {
    const handlemodalOpened = () => {
      toggleMenuDisplay(false);
    };

    document.addEventListener('modalOpened', handlemodalOpened);

    return () => document.removeEventListener('modalOpened', handlemodalOpened);
  }, []);

  return (
    <>
      <button
        className={className}
        onClick={() => toggleMenuDisplay()}
        aria-expanded={isMobileMenuOpened}
        data-testid="toggle-mobile-nav"
      >
        {isMobileMenuOpened ? (
          <Image
            src={closeIcon}
            width={20}
            height={20}
            alt={t('close-icon-alt')}
          />
        ) : (
          <HamburgerIcon />
        )}
      </button>
      <div className={clsx(isMobileMenuOpened ? 'block' : 'hidden')}>
        {children}
      </div>
    </>
  );
}
