'use client';

import { useTranslations } from 'next-intl';
import { useEffect } from 'react';

import { useAuthData } from '@/hooks/useAuthData';
import { ScreenSize, useScreenSize } from '@/hooks/useScreenSize';

import UserModal from '../../UserModal';
import useUserModal from '../hooks/useUserModal';
import MobileMenuItem from '../shared/MobileMenuItem';
import MobileMenuItemContainer from '../shared/MobileMenuItemContainer';
import MobileSubMenuItem from '../shared/MobileSubMenuItem';

export default function NuveenMobileUserMenuItem() {
  const { isAuthenticated, isLoading } = useAuthData();
  const t = useTranslations('nav');
  const screenSize = useScreenSize();

  const {
    isClosable,
    modalType,
    email,
    closeModal,
    logout,
    handleLoginSuccess,
    showLoginModal,
    showSignupModal,
    showResetModal,
  } = useUserModal({ isMobile: true });

  useEffect(() => {
    if (isLoading) return;

    if (!isAuthenticated && screenSize < ScreenSize.SM) {
      showSignupModal();
    }
  }, [isLoading, screenSize]);

  return (
    <>
      <MobileMenuItemContainer>
        <MobileMenuItem dataTestId="nav-members">{t('members')}</MobileMenuItem>
        {isAuthenticated ? (
          <>
            <ul role="menu">
              <MobileSubMenuItem
                href={'/account/reservations'}
                dataTestId="nav-reservations"
              >
                {t('reservations')}
              </MobileSubMenuItem>
              <MobileSubMenuItem
                onClick={() => logout()}
                dataTestId="nav-logout"
              >
                {t('logout')}
              </MobileSubMenuItem>
            </ul>
          </>
        ) : (
          <ul role="menu">
            <MobileSubMenuItem onClick={showLoginModal} dataTestId="nav-login">
              {t('login')}
            </MobileSubMenuItem>
            <MobileSubMenuItem
              onClick={showSignupModal}
              dataTestId="nav-signup"
            >
              {t('signup')}
            </MobileSubMenuItem>
          </ul>
        )}
      </MobileMenuItemContainer>

      {modalType && (
        <UserModal
          modal={modalType}
          email={email}
          closable={isClosable}
          onLoginSuccess={handleLoginSuccess}
          onClose={closeModal}
          onSignUpClick={showSignupModal}
          onLoginClick={showLoginModal}
          onResetClick={showResetModal}
        />
      )}
    </>
  );
}
