import dynamic from 'next/dynamic';

const ModalDialog = dynamic(() =>
  import('./ModalDialog').then((mod) => mod.ModalDialog)
);
const Login = dynamic(() => import('./login/Login').then((mod) => mod.Login));
const SignUp = dynamic(() =>
  import('./signup/Signup').then((mod) => mod.SignUp)
);
const ResetPasswordEmailForm = dynamic(() =>
  import('./Header/ResetPasswordEmailForm').then((mod) => mod.default)
);

type UserModalProps = {
  modal: UserModals;
  onLoginSuccess: () => void;
  onClose: () => void;
  onSignUpClick: () => void;
  onLoginClick: () => void;
  onResetClick: () => void;
  email?: string;
  closable?: boolean;
};

export enum UserModals {
  LOGIN = 'login',
  SIGNUP = 'signup',
  RESET = 'reset',
}

export default function UserModal({
  modal,
  onLoginSuccess,
  onClose,
  onSignUpClick,
  onLoginClick,
  onResetClick,
  email,
  closable = true,
}: UserModalProps) {
  return (
    <ModalDialog
      dataTestId="modal-dialog-login"
      onClose={onClose}
      width={600}
      closable={closable}
    >
      {modal === UserModals.LOGIN && (
        <Login
          email={email}
          onSignUpClick={onSignUpClick}
          onSuccess={onLoginSuccess}
          onReset={onResetClick}
        />
      )}
      {modal === UserModals.SIGNUP && <SignUp onLoginClick={onLoginClick} />}
      {modal === UserModals.RESET && <ResetPasswordEmailForm />}
    </ModalDialog>
  );
}
