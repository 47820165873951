import * as React from 'react';

export default function HamburgerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={17}
      fill="none"
      className="fill-teal-5 header-transparent:fill-white"
    >
      <title>{'Open menu mobile Icon'}</title>
      <rect width={20} height={2.5} rx={0.5} />
      <rect width={20} height={2.5} y={7} rx={0.5} />
      <rect width={20} height={2.5} y={14} rx={0.5} />
    </svg>
  );
}
