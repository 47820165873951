'use client';

import { useTranslations } from 'next-intl';

import { useAuthData } from '@/hooks/useAuthData';

import useDropDowns from '../hooks/useDropdowns';
import BaseHorizontalNav from '../shared/BaseHorizontalNav';
import LinkMenuItem from '../shared/LinkMenuItem';
import MenuItem from '../shared/MenuItem';
import NuveenDesktopUserDropdown from './NuveenDesktopUserDropdown';

export default function NuveenHorizontalNav() {
  const { isAuthenticated } = useAuthData();
  const t = useTranslations('nav');
  const { menuRef, openedDropdownId, onKeyDown, closeDropdown, openDropdown } =
    useDropDowns();

  return (
    <BaseHorizontalNav
      className="justify-end mr-5"
      menuRef={menuRef}
      onMouseLeave={() => closeDropdown()}
    >
      {isAuthenticated && (
        <MenuItem
          className="max-lg:hidden"
          url="/account/reservations"
          dataTestId="nav-reservations"
          onFocus={() => closeDropdown()}
          onMouseEnter={() => closeDropdown()}
        >
          <LinkMenuItem href="/account/reservations">
            {t('reservations')}
          </LinkMenuItem>
        </MenuItem>
      )}

      <NuveenDesktopUserDropdown
        className="max-lg:hidden"
        isExpanded={openedDropdownId === 'user'}
        onKeyDown={(e) => onKeyDown(e, 'user')}
        onMouseEnter={() => openDropdown('user')}
        onSubMenuClick={closeDropdown}
      />
    </BaseHorizontalNav>
  );
}
