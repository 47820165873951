import { useTranslations } from 'next-intl';
import Image from 'next/image';
import React from 'react';

import { Link } from '@/components/Link';
import checkmarkIcon from '@/public/icons/checkmarkIcon.svg';

type SubMenuItemProps = {
  href?: string;
  dataTestId: string;
  isExternalLink?: boolean;
  isSelected?: boolean;
  locale?: string;
  onClick?: () => void;
} & Omit<React.ComponentPropsWithoutRef<'li'>, 'onClick'>;

export default function SubMenuItem({
  children,
  href,
  dataTestId,
  isExternalLink,
  isSelected,
  locale,
  onClick,
  ...rest
}: SubMenuItemProps) {
  const t = useTranslations('nav');

  return (
    <li
      className="flex items-baseline gap-2 leading-6"
      role="menuitem"
      {...rest}
    >
      {href ? (
        <Link
          href={href}
          rel={isExternalLink ? 'noopener' : ''}
          target={isExternalLink ? '_blank' : '_self'}
          data-testid={dataTestId}
          locale={locale}
        >
          {children}
        </Link>
      ) : (
        <button data-testid={dataTestId} onClick={onClick}>
          {children}
        </button>
      )}
      {isSelected && (
        <Image
          className="mt-[5px] mr-4"
          src={checkmarkIcon}
          alt={t('checkmark-icon-alt')}
          aria-hidden="true"
        />
      )}
    </li>
  );
}
