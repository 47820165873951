'use client';

import { useTranslations } from 'next-intl';

import { usePathname } from '@/navigation';

import { MenuKey, STANDARD_MENU_ITEMS } from '../constants';
import useDropDowns from '../hooks/useDropdowns';
import BaseHorizontalNav from '../shared/BaseHorizontalNav';
import HorizontalNavDropdown from '../shared/HorizontalNavDropdown';
import LinkMenuItem from '../shared/LinkMenuItem';
import MenuItem from '../shared/MenuItem';
import SubMenuItem from '../shared/SubMenuItem';
import DefaultDesktopUserDropdown from './DefaultDesktopUserDropdown';
import JoinNow from './JoinNow';
import LanguageDropdown from './LanguageDropdown';

export default function DefaultHorizontalNav() {
  const t = useTranslations('nav');
  const { menuRef, openedDropdownId, onKeyDown, closeDropdown, openDropdown } =
    useDropDowns();
  const pathname = usePathname();

  return (
    <BaseHorizontalNav
      className="items-center justify-end lg:justify-between"
      menuRef={menuRef}
      onMouseLeave={() => closeDropdown()}
    >
      {STANDARD_MENU_ITEMS.map(
        ({ key, isExternal, url, subItems, widthSubMenu }) => {
          if (subItems && subItems.length > 0) {
            return (
              <HorizontalNavDropdown
                key={key}
                dataTestId={`nav-${key}`}
                className="max-lg:hidden"
                isHighlighted={
                  pathname === url ||
                  !!subItems?.some((subItem) => pathname === subItem.url)
                }
                widthSubMenu={widthSubMenu}
                onKeyDown={(e) => onKeyDown(e, key)}
                onMouseEnter={() => openDropdown(key)}
                isExpanded={openedDropdownId === key}
                label={t(key)}
              >
                {subItems.map(({ key, url, isExternal }) => (
                  <SubMenuItem
                    key={key}
                    href={url}
                    dataTestId={`nav-${key}`}
                    isExternalLink={isExternal}
                  >
                    {t(key)}
                  </SubMenuItem>
                ))}
              </HorizontalNavDropdown>
            );
          }

          return (
            <MenuItem
              key={key}
              url={url}
              className="max-lg:hidden"
              onFocus={() => closeDropdown()}
              onMouseEnter={() => closeDropdown()}
            >
              {url && (
                <LinkMenuItem
                  href={url}
                  isExternalLink={isExternal}
                  dataTestId={`nav-${key}`}
                >
                  {t(key)}
                </LinkMenuItem>
              )}
            </MenuItem>
          );
        }
      )}

      <DefaultDesktopUserDropdown
        className="max-lg:hidden"
        isExpanded={openedDropdownId === MenuKey.Members}
        onKeyDown={(e) => onKeyDown(e, MenuKey.Members)}
        onMouseEnter={() => openDropdown(MenuKey.Members)}
        onSubMenuClick={closeDropdown}
      />

      <LanguageDropdown
        className="max-lg:hidden"
        isExpanded={openedDropdownId === MenuKey.Locale}
        onKeyDown={(e) => onKeyDown(e, MenuKey.Locale)}
        onMouseEnter={() => openDropdown(MenuKey.Locale)}
        onSubMenuClick={closeDropdown}
      />

      <JoinNow onMouseEnter={closeDropdown} />
    </BaseHorizontalNav>
  );
}
