'use client';

import { ReactNode } from 'react';

import { useAuthData } from '@/hooks/useAuthData';

type AuthenticatedFilterProps = {
  children: ReactNode;
  value?: boolean;
};

function AuthenticatedFilter({
  children,
  value = true,
}: AuthenticatedFilterProps) {
  const { isAuthenticated } = useAuthData();

  return isAuthenticated === value ? children : null;
}

export default AuthenticatedFilter;
