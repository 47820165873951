'use client';

import { useSupplier } from '@/hooks/useSupplier';

type HeaderFactoryProps = {
  nuveenHeader: React.ReactNode;
  defaultHeader: React.ReactNode;
};

function HeaderFactory({ nuveenHeader, defaultHeader }: HeaderFactoryProps) {
  const { isNuveenSupplier } = useSupplier();

  if (isNuveenSupplier) {
    return nuveenHeader;
  }

  return defaultHeader;
}

export default HeaderFactory;
