import React from 'react';

import { Link } from '@/components/Link';

import clsx from 'clsx';

type MobileMenuItemProps = {
  href?: string;
  isExternal?: boolean;
  children: React.ReactNode;
  dataTestId: string;
  onClick?: () => void;
};

export default function MobileMenuItem({
  children,
  href,
  isExternal,
  dataTestId,
  onClick,
}: MobileMenuItemProps) {
  const defaultClassName =
    'font-bold text-teal-5 text-base leading-4 flex items-baseline gap-1';

  if (href) {
    return (
      <Link
        href={href}
        className={defaultClassName}
        rel={isExternal ? 'noopener' : ''}
        target={isExternal ? '_blank' : '_self'}
        data-testid={dataTestId}
      >
        {children}
      </Link>
    );
  }

  if (onClick) {
    return (
      <button
        className={clsx(defaultClassName, 'font-bold')}
        data-testid={dataTestId}
        onClick={onClick}
        type="button"
      >
        {children}
      </button>
    );
  }

  return (
    <div className={clsx(defaultClassName)} data-testid={dataTestId}>
      {children}
    </div>
  );
}
